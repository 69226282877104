@font-face {
  font-family: "Kommissar Web";
  src: url("../fonts/KommissarWeb-Regular.woff2") format("woff2"),
    url("../fonts/KommissarWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kommissar Web";
  src: url("../fonts/KommissarWeb-Extralight.woff2") format("woff2"),
    url("../fonts/KommissarWeb-Extralight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kommissar Web";
  src: url("../fonts/KommissarWeb-Light.woff2") format("woff2"),
    url("../fonts/KommissarWeb-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kommissar Web";
  src: url("../fonts/KommissarWeb-Medium.woff2") format("woff2"),
    url("../fonts/KommissarWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
